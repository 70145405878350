@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body {
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100%;
  background-color: #F8407C;
  color: white;
}

#root {
  overflow: hidden;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: none !important;
  cursor: pointer;
}


.footer a:hover {
  text-decoration: underline !important;
}

/* a span {
  font-size: .8rem;
} */

:root .MuiChip-root {
  font-family: "sarjeta light";
  height: 23px;
  width: 24px;
  margin-top: 2px;
  padding: 0;
}

:root .MuiChip-root span {
  padding: 0;
  font-size: .75rem;
}

* {
  box-sizing: border-box;
}

.zoom {
  height: 400px;
  width: 90%;
  overflow: hidden;
  min-height: max-content;
}

.zoom .building-img {
  width: 100%;
  transition: transform .2s;
  object-fit: cover;
}

.zoom:hover .building-img {
  transform: scale(1.2);
}


.zoom:hover .building-img.lock {
  width: 100%;
  transform: scale(1);
}

@font-face {
  font-family: "sarjeta old";
  src: url("../fonts/sarjeta-black.woff2");
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "sarjeta black";
  src: url("../fonts/sarjeta-black.woff2");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "sarjeta italic";
  src: url("../fonts/Old-Press-Italic.woff2");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "sarjeta light";
  src: url("../fonts/sarjeta-Light.woff");
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: "sarjeta thin";
  src: url("../fonts/sarjeta-Thin.woff");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "sarjeta regular";
  src: url("../fonts/sarjeta-Regular.woff");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "sarjeta medium";
  src: url("../fonts/sarjeta-Medium.woff");
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}

:root label {
  line-height: 1.107;
}

ul {
  list-style: none;
  padding: 0;
}

:root body {
  font-family: 'sarjeta regular', -apple-system, BlinkMacSystemFont, Helvetica, Helvetica Neue, sans-serif;
}

a,
.MuiTypography-root-MuiLink-root {
  color: white !important;
}

.Carousel-indicators-2 {
  margin-top: -2rem !important;
}




/* // Scrollbars style */

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 5px;
  background: transparent !important;
}



/* Track */
::-webkit-scrollbar-track {
  border-radius: 22px;
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 255, 128, 0.75);
  border-radius: 15px;
}


::-moz-selection {
  /* Code for Firefox */
  color: rgba(0, 255, 128, 0.75);
  background: transparent;
}

::selection {
  color: rgba(0, 255, 128, 0.75);
  background: transparent;
}

.bg-light {
  background-color: rgba(255, 0, 83, 0.75);
}

.bg-dark {
  background-color: transparent;
  color: white;
}


hr {
  background-color: #ad0058cf;
  border: none;
}

.container {
  background-color: #F8407C;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.boxShadow {
  box-shadow: rgba(148, 0, 69, 0.75) 2px 72px 42px -30px;
}


.green {
  background-image: linear-gradient(to right, rgba(0, 255, 128, 0.75), #0ebbff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.borderRadius {
  border-radius: 20px;
}