:root {
  --space-default: 1.875;
}

/* MUI FONTS BASE OVERRIDE */

:root .Toastify__progress-bar {
  opacity: 0 !important;
}

:root .MuiFormHelperText-root {
  margin: .5rem 0 0;
}

:root .MuiMenuItem-root.Mui-selected {
  background: transparent;
  opacity: 1;
  font-weight: bold;
}

:root .MuiBackdrop-root {
  background-color: rgba(0, 3, 31, 0.5);
}

:root .MuiPopover-root .MuiBackdrop-root {
  background-color: transparent;
}

:root .MuiTypography-small {
  font-size: .75rem;
}

:root .MuiButton-root {
  font-family: "sarjeta medium";
  font-size: .875rem;
}

:root .MuiButton-sizeLarge {
  min-height: 46px;
  min-width: fit-content;
}

:root .MuiTypography-body {
  font-size: .875rem;
}

:root .MuiTypography-body1 {
  font-size: .875rem;
}

:root .MuiTypography-body2 {
  font-size: .875rem;
}

:root .MuiButton-outlined {
  border-color: #D9D9D9;
}

:root .MuiButtonBase-root {
  color: #fff;
}

:root .MuiSwitch-track {
  /* background-color: #001AFF; */
}

:root .MuiPaper-elevation.MuiTableContainer-root {
  box-shadow: none;
}

:root tbody .MuiTableRow-root td,
:root tbody .MuiTableRow-root th {
  border: none;
}

:root tbody .MuiTableRow-root .MuiTableCell-root {
  padding-left: .2rem;
}

:root .MuiTableRow-root th {
  padding-left: 0;
}

:root .MuiTableRow-root thead .MuiTableCell-root {
  border-bottom: 1px solid #EBECEF;
}

:root .MuiSlider-markLabel {
  width: 60px;
  display: block;
  white-space: pre-wrap;
  text-align: center;
}


.MuiAutocomplete-root .MuiInput-root {
  flex-direction: column-reverse;
  width: 100%;
}

:root .MuiAutocomplete-inputRoot .MuiAutocomplete-input.MuiInput-input {
  min-width: 100%;

}

:root .MuiAutocomplete-root .MuiAutocomplete-tag {
  margin-right: auto;
}

:root .MuiAutocomplete-root .MuiChip-root {

  background-color: blue !important;
  border-radius: 5px !important;
  margin-right: 0.2rem;
  color: white;
}

:root .MuiAutocomplete-root .MuiChip-root svg {
  fill: white;
}

:root .chip-small {
  position: absolute !important;
  left: 31px !important;
  top: -10px !important;
  width: 18px !important;
  height: 18px !important;
  z-index: 999
}

:root .chip-small span {
  font-size: .48rem;
}

.messenger .MuiDrawer-docked .MuiDrawer-paper {
  overflow-x: initial;
}

@media (max-width: 768px) {
  :root .MuiGrid-root .MuiGrid-item {
    padding-left: 0;
  }
}


:root .MuiPaper-root.MuiDrawer-paper {
  background-color: #ff7790 !important;
}

.triangle {
  position: relative;
  width: 300px;
  height: 0;
  border-bottom: 300px solid white;
  cursor: pointer;
  transition: all 0.5s ease;
}

.triangle-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.triangle.zoomed {
  width: 0;
  border-bottom: 0;
}